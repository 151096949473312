import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["submit"]

  connect() {
    const submit = this.submitTarget
    submit.style.display = "none"
  }

  submit() {
    Rails.fire(this.element, "submit")
  }

}
