import { Controller } from "stimulus"
require("select2")

export default class extends Controller {
  connect() {
    $(this.element).select2()

    // Trigger a native 'change' event when select2:select event happens
    $(this.element).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }
}
