function handleAdditionalInfoCheckbox() {
  document.addEventListener("change", function (event) {
    var target = event.target
    if (!target.matches("input[name=additional_info_toggle]")) return

    var id = target.dataset.inspectionStepItemId
    var additionalInfoGroup = document.querySelector(`#inspection_step_item_${id} .additional-info-group`)

    // Show / Hide remarks textarea
    additionalInfoGroup.classList.toggle('d-none')

    // Clear remarks text if hidden
    var remarksInput = additionalInfoGroup.querySelector('textarea.remarks')
    if (additionalInfoGroup.classList.contains('d-none')) remarksInput.value = null
  })
}

handleAdditionalInfoCheckbox()
