// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require("bootstrap")
window.jQuery = window.$ = require('jquery')

window.lightbox = require("lightbox2")
lightbox.option({
    'resizeDuration': 150,
    'fadeDuration': 20,
    'imageFadeDuration': 0
})

import bsCustomFileInput from 'bs-custom-file-input';
window.bsCustomFileInput = bsCustomFileInput

require('../components/additional_info_checkbox')

document.addEventListener("turbo:load", function() {
    // Bootstrap toasts
    $('.toast').toast('show');

    // Bootstrap custom file input
    bsCustomFileInput.init()

    // Bootstrap tooltip
    $('[data-toggle="tooltip"]').tooltip()
});

// After turbo-frame renders its view
document.addEventListener("turbo:frame-render", function() {
    $('[data-toggle="tooltip"]').tooltip()
});

if (process.env.RAILS_ENV == 'development') {
    require('../components/data_prefill')
}

import "@kollegorna/cocoon-vanilla-js"

import Sortable from 'sortablejs';
window.Sortable = Sortable;

document.addEventListener('turbo:load', function() {
    var element = document.querySelector('#inspection_categories');
    if (!element) return;

    var sortable = Sortable.create(element, {
        animation: 250,
        handle: '.draggable',
        onUpdate: function(event) {
            var element = event.item
            var updatePath = element.dataset.updatePath

            $.ajax({
                type: 'PUT',
                url: updatePath,
                dataType: 'json',
                data: { inspection_category: { row_order_position: event.newIndex } }
            });
        }
    })
})

import "controllers"


// Errbit (Airbrake)
import { Notifier } from '@airbrake/browser';

if (process.env.RAILS_ENV !== 'development' && process.env.RAILS_ENV !== 'test') {
  const airbrake = new Notifier({
    host: 'https://errbit-3ipk.herokuapp.com',
    remoteConfig: false,
    projectId: 1,
    projectKey: '68997fa9db28440f083fd715d6a6baf3'
  });

  airbrake.addFilter((notice) => {
    notice.context.environment = process.env.RAILS_ENV;
    return notice;
  });
}
