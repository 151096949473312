import { Controller } from "stimulus"
import Rails from "@rails/ujs"
window.Pikaday = require('pikaday')

export default class extends Controller {
  static values = {
    minDate: String,
    maxDate: String
  }

  connect() {
    let target = this.element
    target.setAttribute('autocomplete', 'off')
    target.setAttribute('autocorrect', 'off')
    target.setAttribute('autocapitalize', 'off')
    target.setAttribute('spellcheck', 'false')

    new Pikaday({
      field: target,
      toString(date, format) {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      },
      onSelect: function (date) {
        const parentFormId = target.getAttribute('data-parent-form-id')
        const parentForm = document.getElementById(parentFormId)

        Rails.fire(parentForm, "submit")
      },
      minDate: new Date(this.minDateValue),
      maxDate: new Date(this.maxDateValue),
      firstDay: 1,
      theme: 'custom-theme'
    });
  }
}
