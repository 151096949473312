import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["fieldWrapper", "field", "accept", "icon"]

  toggle(event) {
    event.preventDefault()

    const element = this.fieldWrapperTarget
    const field = this.fieldTarget
    const acceptButton = this.acceptTarget
    const icon = this.iconTarget

    if (element.classList.contains('h-0')) {
      icon.classList.add('bi-caret-up-fill')
      element.classList.remove('h-0')

      const scrollInterval = setInterval(function(){
        window.scrollBy(0,100)
      }, 20);

      setTimeout(function(){
        clearInterval(scrollInterval);
      }, 800)

      field.select()
      acceptButton.disabled = true

    }
    else {
      icon.classList.remove('bi-caret-up-fill')
      element.classList.add('h-0')
      acceptButton.disabled = false
    }
  }
}
